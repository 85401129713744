import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'; 
import { BrowserRouter } from 'react-router-dom';
// import Megazen from './components/Megazen/Megazen';
// import Nexttrip from './components/Nexttrip/Nexttrip';
// import Destination from './components/Destination/Destination';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <BrowserRouter>
       <App />
     {/*  <Nexttrip/> */}
      {/* <Destination/> */}
      {/* <Megazen/> */}
    </BrowserRouter>

   
);


