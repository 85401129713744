import "./footer.css"





 
const Footer = () => {

    return (
        <>
            <footer>
                <div class="container" id="contactss">
                    <div className="row"  >
                        <div class=" col-md-12">
                            <div className="footer-part">
                                <div class="left-part">
                                    {/* <img src={greencell_logo} className="footer_logo" alt="" /> */}
                                    <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/gcm_logo_wh.png`} className="footer_logo" alt="" />
                                    

                                    <ul>
                                        <li>
                                            {/* <img src={addressIcon} alt="" /> */}
                                            <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/email.svg`} alt="" />
                                            <p >Unit No. 405, 4th Floor, ‘E’ Wing,
                                                Corporate Avenue, New A. K. Link Road,
                                                Chakala, Andheri (East),
                                                Mumbai – 400059, Maharashtra, India
                                            </p>
                                            
                                        </li>
                                        {/* <li> <img src={callIcon} alt="" /> <a href="tel:(+91)22 6907 4500" className="text-light">(+91) 22 6907 4500</a> </li> */}
                                        <li> <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/phone.svg`} alt="" /> <a href="tel:(+91)22 6907 4500" className="text-light">(+91) 22 6907 4500</a></li>

                                        {/* <li> <img src={emailIcon} alt="" /> <a href="mailto:contact@greencellmobility.com" >contact@greencellmobility.com</a> </li> */}
                                        <li> <img src={ `${process.env.REACT_APP_IMAGEBASE_URL}/email.svg` } alt="" /> <a href="mailto:contact@greencellmobility.com" >contact@greencellmobility.com</a> </li>
                                        <li> <img style={{marginTop:"0px"}} src={ `${process.env.REACT_APP_IMAGEBASE_URL}/LinkedInnn.svg` } alt="" /> <a href="https://www.linkedin.com/company/greencell-mobility/" target="_blank" >www.linkedin.com/company/greencell-mobility</a> </li>

                                        

                                    </ul>
                                </div>
                                <div class="right-part">
                                    <form >
                                        <div className="row">
                                            <div className="col-12">
                                                <h2>Send Us a Message</h2>
                                            </div>
                                            <div className="col-md-6 mb-md-4 mb-3">
                                                <label htmlFor="">Name</label>
                                                <input type="text" className="form-control" name="name" placeholder="Enter Name" />
                                            </div>

                                            <div className="col-md-6 mb-md-4 mb-3">
                                                <label htmlFor="Company">Company</label>
                                                <input type="text" className="form-control" name="company" placeholder="Company" />
                                            </div>

                                            {/* <div className="col-md-6 mb-md-4 mb-3">
                                                <label htmlFor="">Designation</label>
                                                <input type="text" className="form-control" name="designation" placeholder="Enter Designation" />
                                            </div> */}

                                            <div className="col-md-6 mb-md-4 mb-3">
                                                <label htmlFor="">Email</label>
                                                <input type="text" className="form-control" name="email" placeholder=" Enter Email " />
                                            </div>

                                            <div className="col-md-6 mb-md-4 mb-3">
                                                <label htmlFor="">Contact</label>
                                                <input type="text" className="form-control" name="contact" placeholder="Contact Number" />
                                            </div>
                                            {/* <div className="col-md-6 mb-md-4 mb-3">
                                                <label htmlFor="">Business Category</label>
                                                <select name="Business Category" className="form-control" id="select">
                                                    <option value="null">-----</option>
                                                    <option value="Business Development">Business Development</option>
                                                    <option value="Marketing">Marketing</option>
                                                    <option value="HR">HR</option>
                                                    <option value="Finance">Finance</option>
                                                    <option value="Customer Service">Customer Service</option>
                                                    <option value="Legal">Legal</option>
                                                    <option value="Others">Others</option>
                                                </select>
                                            </div> */}

                                            <div className="col-12 mb-md-4 mb-3">
                                                <label htmlFor="">Message</label>
                                                <textarea placeholder="Enter Message" name="message" className="form-control"  ></textarea>
                                            </div>
                                            <div className="col-12 mb-md-4">
                                                <input type="submit" value="Submit" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <p className="copyright-text"> &copy; 2023 by GreenCell Mobility</p>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    );
}



export default Footer