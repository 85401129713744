import React from 'react'
import '../../App.css';
import AboutGreenCell from '../BodyComponents/AboutGreenCell/AboutGreenCell'
import Layout from './Layout' 
import OverView from '../BodyComponents/OverView/OverView'
import Presence from '../BodyComponents/Presence/Presence'
import Glance from '../BodyComponents/Glance/Glance'
import News from '../BodyComponents/News/News'
import Banner from '../BodyComponents/Banner/Banner'

 
const AllComponents = () => {

    return (
        <Layout>
            <Banner />
            <AboutGreenCell/>
            <OverView/>
            <Presence />
            <Glance />
            <News />
        </Layout>
    )
}

export default AllComponents

