import React from 'react'
import '../News/News.css'
import { Link } from 'react-router-dom'

//Assets
import shreeKant from "../../../assets/images/srikant press release (2).png"

const  News=()=> {
    
    const array=[
        {
            img:`${process.env.REACT_APP_IMAGEBASE_URL}/KKM_For-NueGo-Website_PR-Banner_Desktop-Homepage_300-X-300-px.jpg`,
            paragragh:`NueGo Partners with the Exciting New Film "Khel Khel Mein"`,
            link:"#Blog1"
        },
        {
            img:`${process.env.REACT_APP_IMAGEBASE_URL}/anniversary2.webp`,
            paragragh:"NueGo celebrates 2 years of Milestones",
            link:"#Blog2"
        },
        {
            img:`${process.env.REACT_APP_IMAGEBASE_URL}/prideGCM.jpg`,
            paragragh:"GreenCell Mobility Celebrates Pride Month with Commitment to Diversity and Inclusion",
            link:"#Blog3"
        },
  
    ]

    return (

        <section className='news_section'>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Recent News</h2>
                        <div className='news-list'>
                        <ul>
                            {array.map((items,id)=>{
                              return  <li  className='imagenewsss' >                                   
                              <img src={items.img} alt="blog1"  className='imagessss' />
                              <p>{items.paragragh} ​</p>
                              <Link  to={`/news${items.link}`} >Read More 
                               {/* <img src={blogarrow} alt='blogarrow img' />  */}
                               <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/arrow.svg`} alt='blogarrow img'    />   
                               </Link>
                            </li>
                            }) }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    )
}

export default News
