import React from 'react'
import "../AboutGreenCell/aboutGreenCell.css"
// import intercityarrow from '../../../assets/images/arrow.svg'

// import './App.css';

function AboutGreenCell() {
     return (
        <>
            <section className='about_section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about_part">
                                <div className="left-part">
                                    {/* <h2>About Greencell  Mobility</h2> */}
                                    <p> GreenCell has been set up to become India's most reliable shared electric mobility player by leveraging proven global experience, developments in e-mobility technology, and the Government of India’s strong push for electrification of transportation in India.</p>
                                    <p className='mb-0'>NueGo, the intercity arm of Greencell, has achieved a remarkable feat by being recognized in both the Asia Book of Records and India Book of Records for launching India's First Ever All-Woman Electric Intercity Bus in 2023.</p>
                                </div>
                                <div className="right-part">                                   
                                    <ul>
                                        <li style={{  backgroundColor: '#FFECDB'}}>
                                            <h3>IntraCity Buses</h3>
                                            <p>Gross Cost Contracts</p>
                                            <a href="#"> Read More 
                                            {/* <img src={intercityarrow} alt='img'/>  </a> */}
                                            <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/arrow.svg`} alt='img'/>  </a>

                                        </li>
                                        <li style={{  backgroundColor: '#D3F1EF'}}>
                                            <h3>
                                                {/* <img src={Neugo_logo} alt="neugo-logo" /> */}
                                                <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/nuego_logo.svg`} alt="neugo-logo" />

                                             <span>InterCity</span> </h3>
                                            <p>Now, book intercity travel with us</p>
                                            <a href="https://nuego.in?source=gcm" target="_blank"> Book Now 
                                            {/* <img src={intercityarrow} alt='img'/>  */}
                                            <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/arrow.svg`} alt='img'/>  

                                             </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default AboutGreenCell
