
import Layout from "../../AllComponents/Layout"
import "./aboutUs.css";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Scrollbar, Keyboard, History } from 'swiper/modules';

import 'swiper/css/scrollbar';
import { useState } from "react";

const AboutUs = () => {
    let boardofdirectorsdata = [
        {
            imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/DC.jpeg`,
            img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`, name: "Devndra Chawla", position: "MD & Chief Executive Officer", class: "coverImag", linkedIn: "https://in.linkedin.com/in/devendrachawla/"
        },
        {
            imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/Aditya%20Ghosh.jpeg`,
            img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`, name: "Aditya Ghosh", position: "Director", class: "coverImag", linkedIn: "https://in.linkedin.com/in/aditya-ghosh-145569170"
        },
        {
            imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/Partha.png`,
            img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`, name: "Parthasarathy Vankipuram Srinivasa", position: "Independent Director", class: "coverImag", linkedIn: "https://in.linkedin.com/in/parthasarathy-v-s-711a7417"
        },
        
        {
            imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/prasanna.jpeg`, img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`, name: "Prasanna Prakash Desai", position: "Director",
            class: "coverImag", linkedIn: "https://www.linkedin.com/in/prasanna-desai-0b36327/"
        },
        {
            imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/Vibhor%20Talreja.jpeg`,
            img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`, name: "Vibhor Kumar Talreja", position: "Additional Director", class: "coverImag", linkedIn: "https://in.linkedin.com/in/vibhor-kumar-talreja-b872a310"
        },
        {
            imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/shishir.png`, img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`, name: "Shishir Maheshwari", position: "Director",
            class: "coverImag", linkedIn: "https://www.linkedin.com/in/shishirm/"
        },

    ]
    let greencellleadershipdata = [
        {
            imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/DC.jpeg`,
            img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`, name: "Devndra Chawla", position: "Chief Executive Officer", class: "coverImag", linkedIn: "https://in.linkedin.com/in/devendrachawla/"
        },
        {
            imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/vivek.jpg`,
            img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`, name: "Vivek Sahni", position: "Chief Financial Officer", class: "coverImag", linkedIn: "https://in.linkedin.com/in/viveksahni/"
        },
        {
            imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/rajani.jpg`,
            img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`, name: "Rajani Tewari", position: "Chief People Officer", class: "coverImag", linkedIn: "https://in.linkedin.com/in/rajanitewari/"
        },
        {
            imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/vijeet.jpg`,
            img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`, name: "Vijeet Shekhawat", position: "Business Head – B2C", class: "coverImag", linkedIn: "https://in.linkedin.com/in/vijeet/"
        },
        {
            imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/Kamal%20Maheshwari.jpg`,
            img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`, name: "Kamal Maheshwari", position: "Business Head – B2G", class: "coverImag", linkedIn: "https://www.linkedin.com/in/kamal-maheshwari/"
        },
        {
            imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/virender.jpeg`, img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`,
            name: "Virender Singh", position: "Chief Administrative Officer", class: "coverImag", linkedIn: "https://www.linkedin.com/in/virender-singh-78b95a14/"
        },
        // {
        //     imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/ramPrakashGcm.png`, img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`,
        //     name: "Ram Prakash", position: "Head - Vehicle Technology", class: "coverImag", linkedIn: "https://in.linkedin.com/in/ram-prakash-905b275/"
        // },
        // {
        //     imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/Sumit%20Barat.jpeg`, img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`,
        //     name: "Sumit Barat", position: "Head - Corporate Affairs", class: "coverImag", linkedIn: "https://in.linkedin.com/in/sumitbarat"
        // },
       
        // {
        //     imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/arpit.jpg`, img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`,
        //     name: "Arpit Agarwal", position: "Head - Strategy & PMO", class: "coverImag", linkedIn: "https://www.linkedin.com/in/arpit-agarwal-76977342/"
        // },
        // {
        //     imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/dhiren.jpg`, img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`,
        //     name: "Dhiren Gajjar", position: "Head - Information Technology", class: "coverImag", linkedIn: "https://www.linkedin.com/in/dhiren-gajjar-a0a9897/"
        // },
        // {
        //     imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/Punit%20Talreja.jpeg`, img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`,
        //     name: "Punit Talreja", position: "Head - Risk & Audit", class: "coverImag", linkedIn: "https://in.linkedin.com/in/puneet-talreja-84656a17"
        // },

        // {
        //     imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/sharadChandraKedare.png`, img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`,
        //     name: "Sharadchandra Kedare", position: "Head - Operations-B2G", class: "coverImag", linkedIn: "https://in.linkedin.com/in/sharadchandra-kedare-6718361b/"
        // }, 
        // {
        //     imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/Ashish%20Sharma_01.jpg`, img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`,
        //     name: "Ashish Sharma", position: "Head - Operations-B2C", class: "coverImag", linkedIn: "https://www.linkedin.com/in/ashish-sharma-068175a/"
        // },
        // {
        //     imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/Debprakash_01.jpg`, img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`,
        //     name: "Debprakash Das", position: "Head - Corporate Finance", class: "coverImag", linkedIn: "https://www.linkedin.com/in/debprakashfms/"
        // },
        // {
        //     imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/Payal_01.jpg`, img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`,
        //     name: "Payal Saraf", position: "Company Secretary", class: "coverImag", linkedIn: "https://www.linkedin.com/in/payal-sharaf-4308a310/"
        // },
        // {
        //     imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/Ashish%20Goyal_01.jpg`, img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`,
        //     name: "Ashish Goyal", position: "Finance Controller", class: "coverImag", linkedIn: "https://www.linkedin.com/in/ashish-goyal-4a357138/"
        // },
        // {
        //     imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/Rana%20Sachdeva_01.jpg`, img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`,
        //     name: "Rana Sachdeva", position: "Head - Contract Manufacturing", class: "coverImag", linkedIn: "https://in.linkedin.com/in/rana-r-s-sachdeva-69b76132/"
        // },
        // {
        //     imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/Rahul%20GAdi.jpeg`,
        //     img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`, name: "Rahul Gadi", position: "Head - Sales & Distribution", class: "coverImag", linkedIn: "https://in.linkedin.com/in/rahul-gadi-b841767"
        // },
        // {
        //     imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/Punit%20M.jpeg`,
        //     img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`, name: "Punit Mehta", position: "Head - Customer Success", class: "coverImag", linkedIn: "https://in.linkedin.com/in/punitmehta1"
        // },
        // {
        //     imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/Apurva.jpeg`,
        //     img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`, name: "Apurva Sharma", position: "Head - Marketing & Communications", class: "coverImag", linkedIn: "https://in.linkedin.com/in/apurva-sharma-9508017"
        // },
        // {
        //     imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/manisha.jpg`,
        //     img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`, name: "Manisha Srivastava", position: "Head - Legal", class: "coverImag", linkedIn: "https://www.linkedin.com/in/manisha-srivastava-6693655/"
        // },

        // {
        //     imgname: `${process.env.REACT_APP_IMAGEBASE_URL}/Binodji.png`,
        //     img: `${process.env.REACT_APP_IMAGEBASE_URL}/icon.svg`, name: "Binod Singh", position: "Head - Health, Safety and Environment", class: "coverImag", linkedIn: "https://www.linkedin.com/in/binod-singh-alchouni"
        // },
    ]
    const [Data1, setData1] = useState(boardofdirectorsdata)
    const [activeButton, setActiveButton] = useState('button1')
    const [linkedinimage, setLinkedInImage] = useState('')

    const Boardofdirectors = (e) => {

        // setData1(boardofdirectorsdata)
        // setActiveButton("button1")
    }
    const greencellleadership = (e) => {

        // setData1(greencellleadershipdata)
        // setActiveButton("button2")
    }
    return (
        <>
            <Layout>

                <section className='aboutbanner-sec'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <h2 className='headingRout'> Home / <span> About Us </span> </h2>
                                <div className="aboutbannerpart">
                                    <h1>GreenCell Mobility is dedicated to building a platform for eMaaS (Electric Mobility-as-a-Service)</h1>
                                    <figure className="mb-0">
                                        {/* <img src={aboutbannerimg} alt="img" />  */}
                                        <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/aboutbannerimg.png`} alt="img" />

                                    </figure>
                                </div>


                            </div>
                        </div>
                    </div>

                </section>

                <section className="mt-5">
                    <div className="container">
                        <div className="row">
                            <div className='col-12'>
                                <div className='aboutpart'>
                                    <div className='leftpart'>
                                        <h2>GreenCell wants to be the prime mover in building a sustainable growth momentum in the shared e-mobility space </h2>
                                    </div>
                                    <div className='rightpart'>
                                        <p> GreenCell has been promoted by EverSource Capital, the fund manager of India’s pioneering climate impact fund. EverSource Capital is an equal joint venture between Everstone Group, one of Asia’s premier investment groups with assets in excess of US$6 billion across private equity, real estate, credit, climate impact investments and green infrastructure and venture capital; and Lightsource bp, a global leader in development and management of solar energy projects. </p>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </section>

                {/* https://www.youtube.com/watch?v=6TdDfW-On4w */}

                <section className="aboutteam-slider videoLink">
                    <div className="container">
                        <div className="row">
                            <div className='col-12'>


                                {/* <iframe src="https://drive.google.com/file/d/16_yPIx22EHr7SuzqKA38H9r0DlByM_IV/preview" width="" height="" allow="autoplay"></iframe>*/}
                                {/* https://www.youtube.com/watch?v=zy5oXOxzvGA */}
                                <iframe
                                    src="https://www.youtube.com/embed/zy5oXOxzvGA?autoplay=1&mute=1" className="w-100">
                                </iframe>

                                {/* <Swiper
                            slidesPerView={1}
                            centeredSlides={false}
                            slidesPerGroupSkip={1}
                            grabCursor={true}
                            keyboard={{
                                enabled: true,
                            }}
                            breakpoints={{
                                1500: {
                                    slidesPerView: 1,
                                    slidesPerGroup: 1,
                                },
                            }}
                            scrollbar={true}
                            navigation={true}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Keyboard, Scrollbar, Navigation, Pagination]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <img src={newsroom2} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://cdn.magloft.com/github/swiper/images/page-002.jpg" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://cdn.magloft.com/github/swiper/images/page-003.jpg" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://cdn.magloft.com/github/swiper/images/page-004.jpg" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://cdn.magloft.com/github/swiper/images/page-005.jpg" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://cdn.magloft.com/github/swiper/images/page-006.jpg" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://cdn.magloft.com/github/swiper/images/page-007.jpg" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://cdn.magloft.com/github/swiper/images/page-008.jpg" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://cdn.magloft.com/github/swiper/images/page-009.jpg" />
                            </SwiperSlide>
                        </Swiper> */}
                            </div>
                        </div>
                    </div>
                </section>



                <section className='aboutteamsection'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="aboutteam_box">
                                    <div className=" aboutteam_text">
                                        <h2> Our Team </h2>
                                        <p>Some truly talented and purpose-led folks have jumped aboard GreenCell. Each one of them comes with expertise in their domain - modest and jovial yet resolute and hungry to make things happen.</p>

                                        <button className={activeButton === "button1" ? "btn active" : "btn  "} >Board of Directors </button>


                                    </div>

                                    <ul className="teamlist">
                                        {Data1?.map((el, id) => {
                                            return (
                                                <li key={id}>
                                                    <figure>
                                                        <img src={el.imgname} alt="img" className={`teammemberimg ${el.class}`} />
                                                       { el.linkedIn && <span>
                                                            <a href={el.linkedIn} target="_blank">    <img src={el.img} onClick={() => setLinkedInImage()} alt="img" className="" /></a>
                                                        </span>}
                                                    </figure>
                                                    <h3>{el.name}</h3>
                                                    <h4>{el.position}</h4>
                                                </li>
                                            )
                                        })}




                                        {/* <li>
                                            <figure>
                                                <img src={prasanna} alt="img" className="teammemberimg" />
                                                <span>
                                                    <img src={icon} alt="img" className="" />
                                                </span>
                                            </figure>
                                            <h3>Devndra Chawla</h3>
                                            <h4>CEO</h4>
                                        </li>
                                        <li>
                                            <figure>
                                                <img src={prasanna} alt="img" className="teammemberimg" />
                                                <span>
                                                    <img src={icon} alt="img" className="" />
                                                </span>
                                            </figure>
                                            <h3>Devndra Chawla</h3>
                                            <h4>CEO</h4>
                                        </li>
                                        <li>
                                            <figure>
                                                <img src={prasanna} alt="img" className="teammemberimg" />
                                                <span>
                                                    <img src={icon} alt="img" className="" />
                                                </span>
                                            </figure>
                                            <h3>Devndra Chawla</h3>
                                            <h4>CEO</h4>
                                        </li>
                                        <li>
                                            <figure>
                                                <img src={prasanna} alt="img" className="teammemberimg" />
                                                <span>
                                                    <img src={icon} alt="img" className="" />
                                                </span>
                                            </figure>
                                            <h3>Devndra Chawla</h3>
                                            <h4>CEO</h4>
                                        </li>
                                        <li>
                                            <figure>
                                                <img src={prasanna} alt="img" className="teammemberimg" />
                                                <span>
                                                    <img src={icon} alt="img" className="" />
                                                </span>
                                            </figure>
                                            <h3>Devndra Chawla</h3>
                                            <h4>CEO</h4>
                                        </li>
                                        <li>
                                            <figure>
                                                <img src={prasanna} alt="img" className="teammemberimg" />
                                                <span>
                                                    <img src={icon} alt="img" className="" />
                                                </span>
                                            </figure>
                                            <h3>Devndra Chawla</h3>
                                            <h4>CEO</h4>
                                        </li>
                                        <li>
                                            <figure>
                                                <img src={prasanna} alt="img" className="teammemberimg" />
                                                <span>
                                                    <img src={icon} alt="img" className="" />
                                                </span>
                                            </figure>
                                            <h3>Devndra Chawla</h3>
                                            <h4>CEO</h4>
                                        </li>
                                        <li>
                                            <figure>
                                                <img src={prasanna} alt="img" className="teammemberimg" />
                                                <span>
                                                    <img src={icon} alt="img" className="" />
                                                </span>
                                            </figure>
                                            <h3>Devndra Chawla</h3>
                                            <h4>CEO</h4>
                                        </li> */}
                                    </ul>

                                    <div className=" aboutteam_text">
                                        <button className={activeButton === "button2 " ? "btn active   " : "btn active "} onClick={() => greencellleadership()}>GreenCell Leadership </button>
                                    </div>

                                    <ul className="teamlist">
                                        {greencellleadershipdata?.map((el, id) => {
                                            return (
                                                <li key={id}>
                                                    <figure>
                                                        <img src={el.imgname} alt="img" className={`teammemberimg ${el.class}`} />
                                                        {el.linkedIn && <span>
                                                            <a href={el.linkedIn} target="_blank">
                                                                <img src={el.img} alt="img" className="" />
                                                            </a>
                                                        </span>}
                                                    </figure>
                                                    <h3>{el.name}</h3>
                                                    <h4>{el.position}</h4>
                                                </li>
                                            )
                                        })}
                                    </ul>




                                </div>
                            </div>
                        </div>
                    </div>
                </section>







            </Layout>

        </>
    )
}

export default AboutUs