
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import AllComponents from './components/AllComponents/AllComponents'; 
import NewsPage from './components/Pages/News/NewsPage';
import Portfolio from './components/Pages/Portfolio/Portfolio';
 import Contact from './components/Pages/Contact/Contact';
 import CodeofBusinessConduct from './components/Pages/CodeofBusinessConduct/CodeofBusinessConduct';
import AboutUs from './components/Pages/AboutUs/AboutUs';
import EwasteManagement from './components/Pages/EwasteManagement/EwasteManagement';
// import Portfolios from './components/Pages/Portfolios/Portfolios'; 
 

function App() {
  return (

    <Routes>
      <Route path="/" element={<AllComponents />} />
      <Route path="/news" element={<NewsPage />} />
      <Route path="/portfolio" element={<Portfolio />} />
      {/* <Route path="/portfolios" element={<Portfolios />} />  */}
      <Route path="/CodeofBusinessConduct" element={<CodeofBusinessConduct/>} />
      <Route path="/EwasteManagement" element={<EwasteManagement/>} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/AboutUs" element={<AboutUs />} />
    </Routes>
  );
}

export default App;
