import React, { useEffect, useRef, useState } from 'react'
import './portfolio.css'
import Layout from '../../AllComponents/Layout'

// import bus3 from '../../../assets/images/bus3.png'
// import coma from '../../../assets/images/coma.png'
// import nitinJi from '../../../assets/images/nitinJi.jpeg'
// import portfolioimg1 from '../../../assets/images/portfolio1.png'
// import portfolioimg2 from "../../../assets/images/bannerBg.png"
// import sliderimg1 from '../../../assets/images/sliderimg1.png'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// install Virtual module
import { Keyboard, Scrollbar, Navigation, Pagination } from 'swiper/modules';

const Portfolio = () => {
    const [swiperRef, setSwiperRef] = useState(null);
    const appendNumber = useRef(500);
    const prependNumber = useRef(1);

    // Create array with 500 slides
    const [slides, setSlides] = useState(
        Array.from({ length: 500 }).map((_, index) => `Slide ${index + 1}`)
    );


    const prepend = () => {
        setSlides([
            `Slide ${prependNumber.current - 2}`,
            `Slide ${prependNumber.current - 1}`,
            ...slides,
        ]);
        prependNumber.current = prependNumber.current - 1;
        swiperRef.slideTo(swiperRef.activeIndex + 1, 0);
    };

    const append = () => {
        setSlides([...slides, 'Slide ' + ++appendNumber.current]);
    };

    const slideTo = (index) => {
        swiperRef.slideTo(index - 1, 0);
    };

    return (
        <Layout>
            <section className='portfoliobanner-sec'>
                <div className="container">
                    <div className="row">
                        <div className='col-12'>
                            <h2 className='headingRout'>
                                Home / <span> Portfolio </span>
                            </h2>
                            <div className='banner-part'>

                                <h1>
                                    GreenCell Mobility is dedicated to building a platform for eMaaS (Electric Mobility-as-a-Service)
                                </h1>
                                {/* <p>
                                    It’s time for clean, quiet transportation for all creating a sustainable green future. Communities are growing and evolving, and with that, our transportation needs are changing. Now more than ever, we need safer, more reliable and cleaner options. Every day, GreenCell Mobility works to meet those needs with the world’s best electric vehicle technology solutions.
                                </p>
                                <p>
                                    It aims to integrate and create powerful connected ecosystem of products and services segments in the transportation value chain to offer a robust e-mobility infrastructure capability in the B2G, B2C and B2B domains.
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='portfoliobanner-sider'>
                    <Swiper
                        slidesPerView={1.5}
                        // centeredSlides={true}
                        slidesPerGroupSkip={1}
                        grabCursor={true}
                        keyboard={{
                            enabled: true,
                            loop: true,

                        }}
                        // breakpoints={{
                        //   1920: {
                        //     slidesPerView: 1,
                        //     slidesPerGroup: 1,
                        //   },
                        // }}
                        scrollbar={false}
                        navigation={true}
                        loop={true}
                        // pagination={{
                        //   clickable: true,
                        // }}
                        modules={[Keyboard, Scrollbar, Navigation, Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/portfolio1.jpg`} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/bannerBg.jpg`} />
                        </SwiperSlide>

                        <SwiperSlide>
                            <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/portfolio1.jpg`} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/bannerBg.jpg`} />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="row">
                        <div className='col-12'>
                            <div className='transportationpart'>
                                <div className='leftpart'>
                                    <h2>It’s time for clean, quiet transportation for all creating a sustainable green future. Communities are growing and evolving, and with that, our transportation needs are changing. </h2>
                                </div>
                                <div className='rightpart'>
                                    <p> More than ever, we need safer, more reliable and cleaner options. Every day, GreenCell Mobility works to meet those needs with the world’s best electric vehicle technology solutions. </p>
                                    <p> It aims to integrate and create powerful connected ecosystem of products and services segments in the transportation value chain to offer a robust e-mobility infrastructure capability in the B2G, B2C and B2B domains. </p>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="row">
                        <div className='col-12'>
                            <div className="portfoliobus-box">
                                <div className='inner-box'>
                                    <div className='left-part'>
                                        <h2>Intra-city</h2>
                                        <div className='innercity-box'>

                                            <div className='bus-box'>

                                                <h3>Uttar Pradesh</h3>
                                                <p> GreenCell has deployed 700 state-of-the-art electric buses along with the charging infrastructure across the cities of Lucknow, Kanpur, Varanasi, Agra, Meerut, Ghaziabad, Mathura, Gorakhpur, Prayagraj, Jhansi, Moradabad, Bareilly, Shahjahanpur and Aligarh in the state of UP.</p>
                                            </div>

                                            <div className='bus-box'>
                                                <h3>Surat</h3>
                                                <p>GreenCell Mobility  has been operating 150 intracity electric buses in Surat, Gujarat from two different depos in the city.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='right-part  '>
                                        <h2>Inter-city</h2>
                                        <div className='innercity-box'>
                                            <div className='bus-box'>
                                                <figure>
                                                    {/* <img src={bus3} alt="" /> */}
                                                    <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/bus3.png`} alt="" />

                                                </figure>
                                                <p>
                                                    {/* GreenCell Mobility for MSRTC has deployed 50 electric buses, dubbed "Shivai", for intercity travel across Maharashtra in the cities of Pune, Aurangabad, Kolhapur, Nasik and Solapur. */}
                                                    GreenCell Mobility has launched the Premium AC Electric Coach service brand ‘NueGo’. In the initial phase, 250 electric buses will connect 24 cities across India.
                                                </p>
                                            </div>
                                            <div className='bus-box'>
                                                <h3>MSRTC</h3>
                                                <p>GreenCell Mobility for MSRTC has deployed 50 electric buses, dubbed "Shivai", for intercity travel across Maharashtra in the cities of Pune, Aurangabad, Kolhapur, Nasik and Solapur.</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='portfolioslider-sec'>
                <div className="container">
                    <div className="row">
                        <div className='col-12'>
                            <h2> Empowering the EV Future </h2>
                            <div className='portfolioslider'>
                                <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div className='carousel-box'>
                                                <div className='left-part'>
                                                    {/* <img src={sliderimg1} className="d-block w-100" alt="img" /> */}
                                                    <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/sliderimg1.png`} className="d-block w-100" alt="img" />

                                                </div>
                                                <div className='right-part'>
                                                    {/* <img src={coma} alt='img' /> */}
                                                    <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/coma.png`} alt='img' />

                                                    <h3> It is a certainty that with the strengthening of supply, demand and ecosystem, the EV sector is going to progress..
                                                        <div className=''>
                                                            <a target='_blank' href='https://auto.hindustantimes.com/auto/news/future-of-electric-vehicles-is-what-pm-narendra-modi-said-at-maruti-event-41661743381185.html'> EV Sector making progress </a>
                                                        </div>
                                                    </h3>

                                                    <h4>Shri Narendra Modi,<br />
                                                        Prime Minister of India</h4>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="carousel-item">
                                            <div className='carousel-box'>
                                                <div className='left-part'>
                                                    {/* <img src={nitinJi} className="d-block w-100" alt="img" /> */}
                                                    <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/nitinJi.jpeg`} className="d-block w-100" alt="img" />

                                                </div>
                                                <div className='right-part'>
                                                    {/* <img src={coma} alt='img' /> */}
                                                    <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/coma.png`} alt='img' />

                                                    <h3> “E-mobility is going to be the future mode of economic transport.. There is economic viability for manufacturers...”
                                                        <div className=''>
                                                            <a target='_blank' href='https://www.financialexpress.com/economy/nitin-gadkari-says-india-to-become-automobile-manufacturing-hub-in-5-years-asks-ev-makers-to-cut-price/2122606/'> EV Sector making progress </a>
                                                        </div>
                                                    </h3>

                                                    <h4>Shri Nitin Gadkari, <br /> Minister of Road Transport & Highways of India
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='carousel-controlbtn'>
                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>






        </Layout>
    )
}

export default Portfolio
