import { useState } from 'react'
import { NavLink, Navigate, useNavigate } from 'react-router-dom'
import { ReactComponent as Hamburger } from '../../assets/images/hamburger.svg'
// import HamburgerClose from '../../assets/images/hamburger_close.svg'
// import Brand from '../../assets/images/logo.svg'
// import downArrow from "../../assets/images/down-arrow.svg"
import './navbar.css'


const Navbar = () => {
    const [showNavbar, setShowNavbar] = useState(true)
    const [OpenDropDown, setOpenDropDown] = useState(false)
    const [arrowDirection, setArrowDirection] = useState('right');
    const navigate = useNavigate();

      const handleMouseHover = (isHovering) => {
        setOpenDropDown(isHovering);
        setArrowDirection(isHovering ? 'down' : 'right');
      };

    return (
        <>
            <header>
                <nav className="navbar">
                    <div className="container">
                        <div className="logo" onClick={(e)=>navigate('/')}>
                            {/* <img src={Brand} alt="" className='logo' /> */}
                            <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/logo.svg`} alt="" className='logo' />

                        </div>
                        {      window.innerWidth < "450" &&<div className="logo2" >
                            {/* <img src={Brand} alt="" className='logo' /> */}
                            <img src={`https://production-nuego-cms.blr1.digitaloceanspaces.com/static-contents/prod-v1/achieve2_034fd3c23b.png`} alt="" className='logo2' />

                        </div>}
          
                        <div className="menu-icon" onClick={(e) => setShowNavbar(o => !o)}>
                            <Hamburger />
                        </div>
                        <div className={`nav-elements  ${showNavbar == true ? 'headerBlock' : "headerNone"}`}>
                            <button className='HamburgerClose-btn' onClick={(e) => setShowNavbar(o => !o)}>
                                 {/* <img src={HamburgerClose} alt="HamburgerClose" /> */}
                                 <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/hamburger_close.svg`} alt="HamburgerClose" />

                            </button>
                            <ul>
                                <li>
                                    <NavLink to="/">Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/AboutUs">About </NavLink>
                                </li>
                                {/* <li>
                                    <NavLink to="https://nuego.in/news" target='_blank'>News</NavLink>
                                </li> */}
                                <li>
                                    <NavLink to="/news" >News</NavLink>
                                </li>

                                <li>
                                    <NavLink to="/portfolio">Portfolio</NavLink>
                                </li>
                                <li className='dropdown-bar '
                                         onMouseEnter={() => handleMouseHover(true)}
                                         onMouseLeave={() => handleMouseHover(false)}>
                                    <NavLink to="javascript:void(0);"
                                    >Governance
                                    <svg className={`arrow-icon ${arrowDirection}`} xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                                    {/* <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/down-arrow.svg`} alt="" />  */}
                                    </NavLink>
                                    {OpenDropDown && <ul
                                        className={`dropdown-menu ${showNavbar == false ? "dropdownmenu" : "dropdown-menu"} `}
                                    // className='dropdown-menu'
                                    >
                                        <li>
                                        {/* <NavLink to="javascript:void(0);" class="dropdown-item">Code of Business Conduct</NavLink> */}

                                            <NavLink to="/CodeofBusinessConduct" class="dropdown-item">Code of Business Conduct</NavLink>
                                        </li>
                                        {/* <li>
                                            <NavLink to="javascript:void(0);" class="dropdown-item">E-Waste Management</NavLink>
                                        </li> */}
                                        <li>
                                            <NavLink to="/EwasteManagement" class="dropdown-item">E-Waste Management</NavLink>
                                        </li>

                                    </ul>}
                                </li>
                                <li
                                //  onClick={(e)=>{navigate("#contactss")} }
                                >
                                    <NavLink to="/Contact">Contact</NavLink>
                                </li>
                            </ul>
                        </div>

                        {      window.innerWidth > "1024" &&<div className="logo2" >
                            {/* <img src={Brand} alt="" className='logo' /> */}
                            <img src={`https://production-nuego-cms.blr1.digitaloceanspaces.com/static-contents/prod-v1/achieve2_034fd3c23b.png`} alt="" className='logo2' />

                        </div>}
                    </div>
                </nav>

            </header>


        </>
    )
}

export default Navbar