import React from 'react'
import '../Banner/Banner.css'
// import bannerBg from '../../../assets/images/Main_2.gif'
// import bannerimg1 from '../../../assets/images/bannerimg1.svg' 

 

function Banner() { 
  return (
    <>

      <section className='banner_title'>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Electrifying Mass  <span>Mobility</span></h1>
            </div>
          </div>
        </div>
      </section>

      <section className='homebanner-sec'>
        <div className='leftpart'>
          <figure>
            {/* <img src={bannerimg1} alt="img" /> */} 
            <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/bannerimg1.svg`}   alt="img" /> 
          </figure>
        </div>
        <div className='rightpart'>
          <h2>GreenCell Mobility has been incubated to be a leading pan-India shared electric mobility player</h2>
        </div>
        <div className='leftpart'>
          <h2> About Greencell Mobility </h2>
        </div>
        <div className='rightpart'>
          <figure >
          <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/Main_2.gif`} alt="img" className='w-100' />

            {/* <img src={bannerBg} alt="img" className='w-100' /> */}
          </figure>
        </div>
      </section>
      
    </>
  )
}

export default Banner
