
import React from 'react';
import './ewasteManagement.css';
import Layout from '../../AllComponents/Layout';

// import emanagementimg from '../../../assets/images/emanagementimg.png'
// import lawsimg2 from '../../../assets/images/lawsimg.png'

import pdffile from "../../../assets/Pdf/E-wasteManagment.pdf"

// import arrowpdf from '../../../assets/images/arrow1.svg'



const EwasteManagement = () => {


    return (
        <>

            <Layout>
                <section className='emanagement-sec'>
                    <div className="container">
                        <div className="row">
                            <div className='col-12'>
                                <h2 className='headingRout'>
                                    Home / Governance / <span> E-Waste Management </span>
                                </h2>

                                <div className='emanagementbanner'>
                                        <h1>Navigating the Regulatory Landscape: GreenCell Mobility's Commitment to Compliance and Corporate Governance</h1>
                                    
                                    <div className='rightpart'>
                                        <figure>
                                            {/* <img src={emanagementimg} alt='img' /> */}
                                            <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/emanagementimg.jpg`} alt='img' />

                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>


                <section>
                    <div className="container">
                        <div className="row">
                            <div className='col-12'>
                                <div className='emanagementtext'>
                                    <h2>With an exhaustive conformity policy and monitoring platform, we have nominated Compliance Champions across the organization.</h2>
                                    <div className='rightpart'>
                                        <p>Statutory & Regulatory compliances have become critically important for businesses, more so with an ever-evolving regulatory environment that requires companies to be vigilant and fully compliant to various regulations. </p>
                                        <p> At GreenCell Mobility, we are fully committed towards ensuring highest standards of Corporate Governance, Risk Management and Compliance adherence framework. With an exhaustive conformity policy and monitoring platform, we have nominated Compliance Champions across the organization.</p>
                                        {/* <a href='#'> Read More </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section>
                    <div className="container">
                        <div className="row">
                            <div className='col-12'>
                                <div className='ewasteonebox'>
                                    <div className='leftpart'>
                                        {/* <img src={lawsimg2} alt='img' /> */}
                                        <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/lawsimg.jpg`} alt='img' />

                                    </div>
                                    <div className='rightpart'>
                                        <p> E-waste is one of the fastest growing waste streams in the world. While e-waste contains valuable materials such as aluminium, copper, gold, palladium, and silver, it also contains harmful substances like cadmium, lead, mercury amongst others. In the absence of proper awareness, disposing of e-waste in a landfill can result in toxic emissions to the air, water, and soil and pose serious health and environmental hazards. </p>
                                        <p> Under the guidelines of the Ministry of Environment, Forest and Climate Change, Government of India, E-Waste (Management) Rules, 2016, GreenCell Mobility stands committed to implement E-Waste Rules.</p>
                                        <p> The company has tied -up with GreenZon Recycling Pvt. Ltd; authorized by CPCB - have the expertise in assisting Producers in fulfilling their compliances related to EPR and E-Waste management.</p>

                                        <a href={pdffile} target="_blank">Download PDF 
                                        
                                        {/* <img src={arrowpdf} alt='img' />  */}
                                        <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/arrow1.svg`} alt='img' /> 

                                        </a>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </Layout >

        </>
    )
}

export default EwasteManagement
