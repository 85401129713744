import React, { useEffect, useState } from 'react'
import './codeofBusinessConduct.css'
import Layout from '../../AllComponents/Layout';

// import conductImg from '../../../assets/images/codeofbusinessconduct.png';
// import star from '../../../assets/images/star.svg'
// import arrow2 from '../../../assets/images/arrow2.svg'

import Environment from "../../../assets/Pdf/EHS-SR-Policy-Rev-Due.pdf"
import AntiCorrupation from "../../../assets/Pdf/Anti-Corruption-Policy.pdf"
import AntiMoney from "../../../assets/Pdf/Anti-Money-Laundering-Policy.pdf"
import CoC from "../../../assets/Pdf/CoC-for-Prevention-of-Insider-Trading.pdf"
import Preventation from "../../../assets/Pdf/Decl-of-Fidelity-Secrecy.pdf"
import Employee from "../../../assets/Pdf/Employee-Fair-play-and-Equal-Opportunities.pdf"
import Blowing from "../../../assets/Pdf/Blowing.pdf"
import ConflictofInterestn from "../../../assets/Pdf/Conflict-of-Interest.pdf"
import SexualHarassment from "../../../assets/Pdf/Sexualharasment.pdf"
import GeenCellPrivacyPolicy from "../../../assets/Pdf/GeenCell Privacy Policy.pdf"
import CSR from "../../../assets/Pdf/CSR_Policy_Apr,20,2023.pdf"

const CodeofBusinessConduct = () => {

    const [ActiveTab, setActiveTab] = useState(0)
    const [showTabData, setshowTabData] = useState('')
    const [showTabDataPdf, setshowTabDataPdf] = useState(null)
    const [handleoptionvalue, setHandleOptionValue] = useState(null)
    const [OpenDropDown, setOpenDropDown] = useState(false)
    const [OpenDropDownvalue, setOpenDropDownvalue] = useState("Country’s Laws and Regulations")

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });



    const [sizeVariable, setSizeVariabale] = useState(false)


    let someData = [
        { "data": "Anti-Corruption" },
        { "data": "Anti-Money Laundering" },
        { "data": "Care And Dignity – Prevention Of Sexual Harassment" },
        { "data": "Conflict Of Interest" },
        { "data": "Country’s Laws and Regulations" },
        { "data": "CSR Policy" },
        { "data": "Equal Opportunities" },
        { "data": "Environment, Safety & Health" },
        { "data": "Fidelity and Secrecy" },
        { "data": "Prevention of Insider Trading" },
        { "data": "Privacy Policy" },
        { "data": "Whistle Blowing" }
    ]
    


    useEffect(() => {

        if (ActiveTab == 0) {
            setshowTabDataPdf(AntiCorrupation);
            setshowTabData(`GreenCell prohibits its employees and any other entity or person acting for or on behalf of GreenCell from offering, providing or receiving prohibited gratuities, bribes, gifts, entertainment, facilitating payments, or anything of value to or for the benefit of a government official or any other person contrary to anti-corruption laws or engaging in activities or transactions with sanctioned or blacklisted countries or individuals parties contrary to applicable sanction laws or engaging in any corrupt, fraudulent, coercive or collusive practice.
    
            GreenCell takes zero tolerance approach to corruption and mandates its employees to act professionally, fairly and with integrity in all the business dealings and relationships.
    
            GreenCell has established a detailed ‘Anti-Corruption’ policy to enhance and provide further guidance to the standards of conduct regarding bribery and corruption and outlines acceptable and non-acceptable behaviors to ensure compliance with anti-corruption and sanction laws.
    
            This policy includes compliance with all laws, domestic and foreign, prohibiting improper payments, gifts or inducements of any kind to and received from any person, including officials in the private or public sector, customers and suppliers.`);
        } else if (ActiveTab == 1) {
            setshowTabDataPdf(AntiMoney);
            setshowTabData(`With a view to put in place standards of compliance for itself and its employees in respect to its activities and financial dealing, GreenCell has established a detailed ‘Anti-Money Laundering Policy’ and various procedures for the prevention of money laundering and terrorist financing and ensuring their effectiveness and compliance with all relevant and regulatory requirements.`);
        } else if (ActiveTab == 2) {
            setshowTabDataPdf(SexualHarassment);
            setshowTabData(`GreenCell is committed to create and environment in which all individuals are treated with respect and dignity and promote a gender sensitive work environment. Under no circumstances, GreenCell will tolerate any form of sexual discrimination and/or harassment and is committed to take all necessary steps to ensure that its employees are not subjected to any form of discrimination and/or sexual harassment.
    
            A detailed policy has been put in place covering the provisions of recent legislation of Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013 and rules made thereunder.`);
        } else if (ActiveTab == 3) {
            setshowTabDataPdf(ConflictofInterestn);
            setshowTabData(`It is the company’s policy to avoid situations that create an actual, potential or perceived conflict between an individual’s personal interests and the interest of the company. Such conflict of interest exists when an individual’s loyalties or actions are divided between the company’s interests, or those of another, such as a competitor, supplier, customer or personal business.
    
            GreenCell has put in place a broad framework on managing conflicts of interest everyone to ensure adherence to high ethical standards, and to avoid situations that create an actual or potential conflict between personal interests and the interests of the company.`);
        } else if (ActiveTab == 4) {
            setshowTabDataPdf(null);
            setshowTabData(`Everyone is expected to acknowledge, respect and comply with Indian laws and regulations. This includes compliance with customs and traditions and conformity to trade procedures, including licensing, documentation, and other necessary formalities, as applicable.
    
            The Company seeks to contribute to the social and economic development of the countries it operates in and therefore, all business activities should be conducted in an ethical manner and support in promoting the interest of societies at large.
    
            If any individual or entity involved in doing business with the company commits an offence that violates the applicable country’s law; the contract of employment or service with the company will be subject to immediate termination regardless of any penalties applied as a result of criminal proceedings.
    
            Employees must not use their position, or the relationships established in the course of their work to inappropriately influence or interfere with action being considered through an internal investigation or external law authorities.`);
        } 
        else if (ActiveTab == 5) {
            setshowTabDataPdf(CSR);
            setshowTabData(`Greencell's corporate social responsibility (CSR) statement reflects the Company’s goals of giving to the society because we firmly believe that a sustained economic performance, combined with environmental and social stewardship leads to a holistic business growth for any company`);
        }
        else if (ActiveTab == 6) {
            setshowTabDataPdf(Employee);
            setshowTabData(`The Company believes all its employees have the right to work in a safe and conducive work environment that is discrimination free.
    
            GreenCell is committed to providing an environment which is free from harassment on the grounds of their race, caste, religion, color, ancestry, marital status, gender, sexual orientation, age, nationality, ethnic origin or disability and aims to be supportive of the dignity and self-esteem of individuals and where diverse talents can flourish.`);
        } else if (ActiveTab == 7) {
            setshowTabDataPdf(Environment);
            setshowTabData(`GreenCell Mobility and its SPVs manage Health, Safety and Environment (HSE) responsibilities as a priority. We are committed to continually improving our HSE performance and managing HSE risks associated with our business activities, products, and services by providing alternative sustainable and economical transportation to our customers. GreenCell Mobility and its SPVs aim to prevent accidents, incidents, and occupational illness to people and the environment and deliver customer satisfaction.`);
        } else if (ActiveTab == 8) {
            setshowTabDataPdf(Preventation);
            setshowTabData(`GreenCell has established a procedure for its Employees to make a declaration upon joining, of maintaining a high level of fidelity and secrecy of any Trade Secret Information.`);
        } else if (ActiveTab == 9) {
            setshowTabDataPdf(CoC);
            setshowTabData(`GreenCell has established a detailed ‘Code of Conduct for Prevention of Insider Trading’ in order to ensure the highest ethical standards whilst dealing with securities added to the restricted list/grey list by persons to whom it is applicable. The code also serves to implement the requirements of applicable regulations on such matters.`);
        } else if (ActiveTab == 10) {
            setshowTabDataPdf(GeenCellPrivacyPolicy);
            setshowTabData(`GreenCell Mobility and its direct and indirect subsidiaries (which together comprise “GCM”), are committed to meeting legal and regulatory requirements regarding data protection and privacy where it conducts its business activities. This Privacy Policy (“Policy”) defines the minimum standards with respect to GCM collecting, processing, or otherwise using personal data, including information that may be considered as sensitive Personal Data (“Personal Data”) about individuals including GCM associates, business contacts, customers, or vendors (“Individuals”).`);
        } else if (ActiveTab == 11) {
            setshowTabDataPdf(Blowing);
            setshowTabData(`GreenCell is committed to developing an open and transparent culture where it is safe for all employees to report their bona fide concerns about any perceived wrongdoing within the company. GreenCell has envisaged providing an avenue for employees to confidentially disclose or report bona fide concerns over any perceived wrongdoing within the company to a person with supervisory authority.
    
            A detailed policy has been put in place on Whistle Blowing and Protected Disclosures.`);
        }

    }, [ActiveTab])

    const showDataFun = (e, id, dts) => {

        setActiveTab(id)
        setOpenDropDownvalue(dts?.data)
    }

    const options = [
        {
            value: "hello1", key: "hello1"

        },
        {
            value: "hello2", key: "hello2"

        },
        {
            value: "hello3", key: "hello3"
        }
    ]

    const handlechange = () => {
        setHandleOptionValue(null)
    }










    useEffect(() => {

        const handleResize = () => {

            setWindowSize({

                width: window.innerWidth,

                height: window.innerHeight

            });

        };



        // Add event listener for window resize

        window.addEventListener("resize", handleResize);



        // Remove the event listener on component unmount

        return () => {

            window.removeEventListener("resize", handleResize);

        };



    }, []);



    React.useEffect(() => {

        if (windowSize.width < 768 && windowSize.width > 667) {

            setSizeVariabale(true)

        }

        else if (windowSize.width < 667 && windowSize.width > 0) {

            setSizeVariabale(true)

        }

        else {
            setSizeVariabale(false)
        }





    }, [windowSize])


    return (
        <>

            <Layout>
                <section className='conductbanner-sec'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <h2 className='headingRout'> Home / Governance /<span> Code of Business Conduct </span> </h2>
                                <div className="conductbanner_text">
                                    <h1>Business practices governed by integrity, honesty, fair dealing and full compliance with all applicable laws.</h1>
                                    <figure>
                                        {/* <img src={conductImg} alt='img' className='d-block w-100' /> */}
                                        <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/codeofbusinessconduct.jpg`} alt='img' className='d-block w-100' />

                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='bannerbox'>
                                <div className='leftpart'>
                                    <h2>GreenCell’s business practices have been governed by integrity, honesty, fair dealing and full compliance with all applicable laws.</h2>
                                </div>
                                <div className='rightpart'>
                                    <p>GreenCell’s corporate business principles prescribes certain values and principles that has been established in the form of code of business conduct. wherein everything that the employee does, will be and should be, measured against the highest possible standards of ethical and legal business conduct, which should be adhered at all times, inside and outside of work.</p>
                                    <p>Every GreenCell employee and those involved in doing business with the company is required and expected to read, understand and follow GreenCell’s code of business conduct, both in letter and spirit, always bearing in mind that they have a strong sense of responsibility towards themselves, their families, their stakeholders and societies.</p>
                                    {/* <a href='#'>Read More  </a> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <section className='compass-sec'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <h2>The Compass for Success</h2>
                                <h3> Essential Principles to Guide Employees Towards Excellence </h3>
                                <ul>
                                    {/* <li> <img src={star} alt='star img' /> Avoid reputation risks </li>
                                    <li> <img src={star} alt='star img' /> Uphold Legal and Ethical Standards </li>
                                    <li> <img src={star} alt='star img' /> Prioritize Company's Interests </li> */}
                                     <li> <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/star.svg`} alt='star img' /> Avoid reputation risks </li>
                                    <li> <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/star.svg`} alt='star img' /> Uphold Legal and Ethical Standards </li>
                                    <li> <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/star.svg`} alt='star img' /> Prioritize Company's Interests </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>


                <section className='laws-sec'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='laws-box'>
                                    <div className='leftpart'>
                                        {sizeVariable == false ? <ul className='lawslist'>
                                            {someData.map((items, id) => {
                                                return <li className={`${id == ActiveTab ? 'active' : ""}`} onClick={(e) => showDataFun(e, id)}> {items.data} </li>
                                            })}
                                        </ul>


                                         :
                                            <div className='lawslistdropdown mb-3'
                                                onClick={(e) => { setOpenDropDown(o => !o) }}
                                            >
                                                <h3> {OpenDropDownvalue} </h3>
                                                {OpenDropDown && <ul className='lawsdropdown '>
                                                    {someData.map((items, id) => {
                                                        return <li className={`${id == ActiveTab ? 'active ' : ""}`} onClick={(e) => showDataFun(e, id, items)}> {items.data}
                                                        </li>

                                                    })}

                                                </ul>}
                                            </div>
                                        }

                                    </div>
                                    <div className='rightpart'>

                                        {/* <img src={lawsimg} alt='img' /> */}
                                        <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/lawsimg.jpg`} alt='img' />

                                        <div className='tab-content'>
                                            <div className='content1'>
                                                <p>
                                                    {showTabData}
                                                </p>
                                                {showTabDataPdf != null && <a href={showTabDataPdf+'#toolbar=0'} target='_blank'> View PDF
                                                 {/* <img src={arrow2} alt='img' /> */}
                                                 <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/arrow2.svg`} alt='img' />

                                                   </a>}
                                            </div>
                                            <div className='content2 d-none'>
                                                <p>Everyone is expected to acknowledge, respect and comply to the
                                                    Indian laws and regulations. This includes compliance to customs
                                                    and traditions and conformity to trade procedures, including
                                                    licensing, documentation and other necessary formalities, as
                                                    applicable.</p>
                                                <p>The Company seeks to contribute to the social and economic
                                                    development of the countries it operates in and therefore, all
                                                    business activities should be conducted in an ethical manner and
                                                    support in promoting the interest of societies at large.</p>
                                                <a href="#"> View PDF 
                                                {/* <img src={arrow2} alt='img' />   */}
                                                <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/arrow2.svg`} alt='img' />
                                                </a>
                                            </div>
                                            <div className='content3 d-none'>
                                                <p>Everyone is expected to acknowledge, respect and comply to the
                                                    Indian laws and regulations. This includes compliance to customs
                                                    and traditions and conformity to trade procedures, including
                                                    licensing, documentation and other necessary formalities, as
                                                    applicable.</p>
                                                <p>The Company seeks to contribute to the social and economic
                                                    development of the countries it operates in and therefore, all
                                                    business activities should be conducted in an ethical manner and
                                                    support in promoting the interest of societies at large.</p>
                                                <a href="#"> View PDF 
                                                {/* <img src={arrow2} alt='img' />   */}
                                                <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/arrow2.svg`} alt='img' />
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </Layout>

        </>
    )
}

export default CodeofBusinessConduct;

