import React, { useEffect } from 'react'
import '../Presence/Presence.css'
// import presenceimg from '../../../assets/images/img2.svg' 
// import presencemap from '../../../assets/images/Frame.png'
// import presencearrow from '../../../assets//images/arrow.svg'

import { useCountUp } from 'react-countup';


function Presence() {

    const countUpRef = React.useRef(null);
    const countUpRef1 = React.useRef(null);

    const { start, pauseResume, update } = useCountUp({
        ref: countUpRef,
        start: 934559,
        end: 78401162,
        // delay: 100,
        duration: 3,

    });

    const StartWith = useCountUp({
        ref: countUpRef1,
        start: 21000000,
        end: 48221596,
        duration: 3,

    });



    useEffect(() => {

        window.onscroll = function () { myScrollFun() };

    },)


    function myScrollFun(e,r) {

        if (document.documentElement.scrollTop > 1 && r=="start") {
            console.log("snvdbchsgdvjsb")
            start();
            StartWith.start()

        }
        else if(r=="start") {
            start();
            StartWith.start()

        }
    }


    const handleUsernameFocus = ()=>{
        console.log("nbnbfbfnf")
    }

    
    return (
        <>
            <section  onMouseEnter={(e) => myScrollFun(e,"start")} id="sam">
                <div className="container "

                    // onScroll={(e) => myScrollFun(e)}
                    
                    // onFocus={(e)=>handleUsernameFocus()}

                >
                    <div className="row">
                        <div className="col-12">
                            <div className="presence_part">
                                <div className="left-part">
                                    <h2>Our Presence</h2>
                                    <span>
                                        {/* <img src={presencemap} className='w-100' /> */}
                                        <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/Frame.png`} className='w-100' />

                                        {/* {presencemap} */}
                                    </span>
                                    {/* <Map /> */}
                                </div>
                                <div className="right-part">
                                    <p>GreenCell Mobility has been incubated to be a leading pan-India
                                        shared electric mobility player, by leveraging proven global
                                        experience, developments in e-mobility technology and Government
                                        of India’s strong push for electrification of transportation in India.</p>

                                    <div className='presence-part'>
                                        <div className='presenceleft'>
                                            <div className="presence_box presence_box1">
                                                <h4>We are riding to  <strong>Tamil Nadu</strong> now!</h4>
                                                <h5> <b> Southern India </b> goes sustainable </h5>
                                                <a href="https://nuego.in?source=gcm" target='_blank'>Book Now 
                                                    {/* <img src={presencearrow} alt='' />  */}
                                                    <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/arrow.svg`} alt='blogarrow img'/>   

                                                    </a> 
                                                <div>
                                                    
                                                     {/* <img src={presenceimg} alt="presenceimg" className='presence_img' />  */}
                                                     <img src={`${process.env.REACT_APP_IMAGEBASE_URL}/img2.svg`} alt="presenceimg" className='presence_img' /> 

                                                </div>
                                            </div>
                                        </div>

                                        <div className='presenceright'>
                                            <div id="counterLab">
                                                <div className="presence_box presence_box2">
                                                    <h2>Ferried Customers FY24 </h2>
                                                    <h3>
                                                        <div>
                                                            <div ref={countUpRef} />
                                                        </div>
                                                    </h3>
                                                    <p>Compared to 38,929,480 in FY23</p>
                                                </div>
                                                <div className="presence_box presence_box2">
                                                    <h2>Diesel Saved</h2>
                                                    <h3>
                                                        <div>
                                                            <div ref={countUpRef1} />

                                                        </div>
                                                    </h3>
                                                    <p>Litres</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default Presence
